<template>
  <section
    id="header-form"
    class="seccao header-fixo padding-top-normal-medium-down"
  >
    <Header :title="'Solicitar Contacto'" :breadcrums="this.breadcrums" />
    <main class="contactos">
      <div class="main-inner-wrapper">
        <div class="main-coluna-conteudo sem-padding">
          <div class="main-coluna-conteudo-inner-wrapper">
            <h2 class="title-with-strap margin-bottom-20">
              Quer falar connosco?
            </h2>
            <div class="font-size-22 font-weight-500">
              Sim, quero ser contactado pelo seu Gestor de Cliente Glintt e
              entrar na Nova Era da Farmácia.
            </div>
            <form class="form-contacto" action="" method="post">
              <div class="form-linha">
                <div class="input-item item-width-33">
                  <input
                    type="text"
                    value=""
                    placeholder="Código ANF"
                    name="CodigoAnf"
                    id="anf"
                    tarefa="form"
                  />
                </div>
              </div>
              <div class="form-linha">
                <div class="input-item item-width-100">
                  <input
                    type="text"
                    value=""
                    placeholder="Nome da farmácia"
                    name="NomeFarmacia"
                    id="farmacia"
                    tarefa="form"
                  />
                </div>
              </div>
              <div class="form-linha">
                <div class="input-item item-width-50 paddinf-right-10p">
                  <input
                    type="text"
                    value=""
                    placeholder="Nome"
                    name="Nome"
                    id="nome"
                    tarefa="form"
                  />
                </div>
                <!-- <div class="input-item item-width-50 padding-left-10p">
                  <input
                    type="text"
                    value=""
                    placeholder="Apelido"
                    name="Apelido"
                    id="apelido"
                    tarefa="form"
                  />
                </div> -->
              </div>
              <div class="form-linha">
                <div class="input-item item-width-100">
                  <input
                    type="email"
                    value=""
                    placeholder="Email"
                    name="Email"
                    id="email"
                    tarefa="form"
                  />
                </div>
              </div>
              <div class="form-linha">
                <div class="input-item item-width-50 padding-right-10p">
                  <input
                    type="tel"
                    value=""
                    placeholder="Telefone"
                    name="Telefone"
                    id="telefone"
                    tarefa="form"
                  />
                </div>
                <!-- <div class="input-item item-width-50 padding-left-10p">
                                    <input type="text" value="" placeholder="Código Promocional" name="CodigoPromocional" class="button-style"/>
                                </div> -->
              </div>
              <div class="form-linha wrap">
                <label for="Descricao">Mensagem</label>
                <textarea
                  name="Descricao"
                  class="textarea-item item-width-100"
                  id="mensagem"
                  tarefa="form"
                ></textarea>
              </div>
              <div>
                <button
                  type="submit"
                  class="diagonal button font-size-14 padding-right-xl padding-left-xl"
                  id="botaoForm"
                  @click="submitForm('form')"
                >
                  Submeter
                </button>
                <div
                  id="form-resposta"
                  style="margin-top: 5px; font-size: 120%"
                ></div>
              </div>
            </form>
          </div>
        </div>
        <div class="main-coluna-imagens">
          <div class="imagens-wrapper swiper-wrapper">
            <div
              class="swiper-slide"
              style="background-image: url('/assets/imagens/img_contactos.jpg')"
            ></div>
          </div>
          <div class="slider-swiper-button-next"></div>
        </div>
      </div>
      <!-- <AreaUser /> -->
    </main>
    <Footer />
  </section>
</template>

<script
  type="text/javascript"
  src="@/assets/ficheiros/jquery-3.4.1.min.js"
></script>
<script type="text/javascript" src="@/assets/ficheiros/swiper.min.js"></script>

<script>
  // @ is an alias to /src
  import Header from "@/components/Header.vue";
  import Footer from "@/components/Footer.vue";
  import AreaUser from "@/components/Area.vue";

  export default {
    name: "formulario",
    data() {
      return {
        product_id: this.$route.params.id,
        breadcrums: {},
      };
    },
    components: {
      Header,
      Footer,
      AreaUser,
    },
    methods: {
      submitForm: async function (tarefa) {
        event.preventDefault();

        var resposta = document.querySelector("#form-resposta");

        resposta.innerHTML = "A recolher formulário...";

        var elems = document.querySelectorAll("[tarefa='" + tarefa + "']");
        var dados = {};
        dados["dados"] = {};

        for (var elem of elems) {
          var value = elem.id == "termos" ? elem.checked : elem.value;
          if (value == "" || value == null) {
            resposta.innerHTML = "Todos os campos são obrigatórios";
            return false;
          } else {
            dados["dados"][elem.id] = value;
          }
        }

        await this.sendMessage(dados, resposta);
      },
      sendMessage: async function (dados, resposta) {
        console.log(dados);

        let message = "";

        // for(var dado of dados.dados) {
        //     console.log(dado.key + ": " + dado.value)
        // }

        for (let [key, value] of Object.entries(dados.dados)) {
          message += `${key}: ${value}<br>`;
        }

        let subject = "Novo pedido de contacto";
        let to = "sebastiao@byd.pt";
        var url = "https://bydservices.pt/glintt-mailer/index.php";

        const body = {
          internal_mail: 1,
          message,
          subject,
          to,
        };

        resposta.innerHTML = "A enviar a sua mensagem...";

        var res = await this.call_api_post(url, body);

        console.log(res);

        if (res.resultado == "OK") {
          resposta.innerHTML =
            "Obrigado! A sua mensagem foi enviada com sucesso. Brevemente será contactado.";

          window.dataLayer = window.dataLayer || [];
          window.dataLayer.push({
            event: "formulario",
          });
          // Analytics pixel
          if ("ga" in window) {
            tracker = ga.getAll()[0];
            if (tracker)
              tracker.send("event", "formulario", "formulario", "Objetivo");
          }
          // end of analytics pixel

          document.querySelector("form").innerHTML = "";
          document.querySelector("form").style =
            "display: flex;flex-direction: column;align-items: center;padding-top: 50px;";
          document.querySelector("form").innerHTML =
            "<i class='fa fa-check' style='font-size:80px; color: var(--cor-principal)'></i><br>Mensagem enviada com sucesso!<br>";
          document.querySelector("form").innerHTML +=
            "<a href='/destaques' style='text-decoration:none;color:#21BBB3'><b>Veja os nossos destaques</b></a>";
        } else {
          resposta.innerHTML =
            "Alguma coisa correu mal... Por favor entre em contacto por um dos contactos abaixo";
        }
      },
      collect_page_data: async function () {
        var operation = "products/" + this.product_id;
        var headers = {};
        var url = "https://bydservices.pt/glintt-api/public/api/" + operation;

        var product_data = await this.call_api_get(url, headers);

        // console.log(product_data.related_products)

        this.breadcrums = {
          area_name: product_data.area[0].area_name,
          area_id: product_data.area[0].area_id,
          category_name: product_data.area[0].category_name,
          category_id: product_data.area[0].category_id,
          family_name: product_data.product[0].family_name,
          family_id: product_data.product[0].g_family_id,
          product_id: product_data.product[0].id,
          product_name: product_data.product[0].name,
        };

        document.querySelector("#header-form header").style =
          "background-color: #21BBB3 !important;";
      },
      call_api_get: async function (url, headers) {
        try {
          var response = await fetch(url, {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
            },
          });
          var result = await response.json();
          return result;
        } catch (error) {
          console.error("Error:", error);
          return "Error: " + error;
        }
      },
      call_api_post: async function (url, body) {
        try {
          var response = await fetch(url, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(body, null, 2),
          });
          var result = await response.json();
          return result;
        } catch (error) {
          console.error("Error:", error);
          return "Error: " + error;
        }
      },
    },
    mounted() {
      this.collect_page_data();
      // let isLogged = window.sessionStorage.getItem('user_id')
      // const result = isLogged == null ? false : true

      // if(!result) {
      //     window.location.href = "/login"
      // }
      // else {
      //     console.log("session is valid")
      // }

      var $imagensWrapper = $(".seccao-promo-code .imagens-wrapper");

      var galleryThumbs = new Swiper(".gallery-thumbs", {
        spaceBetween: 10,
        slidesPerView: 5,
        freeMode: true,
        watchSlidesVisibility: true,
        watchSlidesProgress: true,
      });

      //Slider
      var SliderPromoCode = new Swiper(
        ".main-coluna-imagens .swiper-container",
        {
          slidesPerView: 1,
          slidesPerGroup: 1,
          navigation: {
            nextEl: ".swiper-button-next",
            prevEl: ".swiper-button-prev",
          },

          thumbs: {
            swiper: galleryThumbs,
          },
        }
      );
    },
    updated() {
      // this.collect_page_data()
      // let isLogged = window.sessionStorage.getItem('user_id')
      // const result = isLogged == null ? false : true
      // if(!result) {
      //     window.location.href = "/login"
      // }
      // else {
      //     console.log("session is valid")
      // }
    },
  };
</script>

<style src="@/assets/style.css"></style>
<style src="@/assets/produtos.css"></style>
<style src="@/assets/responsive.css"></style>
<style src="@/assets/ficheiros/swiper.min.css"></style>
<style src="@/assets/ficheiros/font-awesome.min.css"></style>

<style scoped>
  @media only screen and (min-width: 1024px) and (orientation: landscape) {
    section main.main-reviews,
    .seccao main.contactos {
      max-height: calc(100vh - 108px - 10vh) !important;
      height: calc(100vh - 108px - 8.35vh);
    }
    .seccao.header-fixo:not(#second) main:not(.listagem-produtos-com-footer) {
      padding-top: calc(108px) !important;
    }
    .seccao.header-fixo main .main-inner-wrapper .main-coluna-conteudo {
      padding-top: 10px !important;
      padding-bottom: 0px !important;
    }
    textarea {
      min-height: 50px !important;
    }
    .seccao header {
      padding-bottom: 20px !important;
    }
  }

  .footer-contactos {
    height: 10vh;
  }
  #botaoForm::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #21bbb3 !important;
    transform: skew(0deg) !important;
    -webkit-transform: skew(0deg) !important;
    display: inline-block;
    z-index: -1;
  }

  .main-coluna-conteudo-inner-wrapper {
    align-self: center;
  }

  @media only screen and (max-width: 640px) {
    .main-coluna-imagens {
      margin-bottom: 20px;
    }
  }
</style>
<style>
  #header-form header {
    padding-top: 22px !important;
    padding-bottom: 22px !important;
  }
</style>
